import { createApp } from 'vue'
import App from './App.vue'
import VueKonva from 'vue-konva';
import router from './router'
import store from './store'
import axios from 'axios'
import '@/assets/bootstrap.min.css'
import '@/assets/bootstrap.bundle.min.js'
import '@formkit/themes/genesis'
import { genesisIcons } from '@formkit/icons'

import { plugin as formkit, defaultConfig as formDefaultConfig } from '@formkit/vue';
import { createFloatingLabelsPlugin, createAutoHeightTextareaPlugin  } from '@formkit/addons';
import '@formkit/addons/css/floatingLabels'
import '@/assets/style.css'


const formKitConfig = formDefaultConfig({
    theme: 'genesis',
    plugins: [
        createFloatingLabelsPlugin({
            useAsDefault: true, // defaults to false
        }),
        createAutoHeightTextareaPlugin(),
    ],
    icons: {
        ...genesisIcons
    }
})


if (window.location.hostname === 'designerfvus.hsco.es' || window.location.hostname === 'designerprod.hsco.es') {
    axios.defaults.baseURL = `${location.protocol}//${location.hostname}/`
} else {
    axios.defaults.baseURL = `${location.protocol}//${location.hostname}:3000/`
}



createApp(App).use(store).use(router).use(VueKonva).use(formkit, formKitConfig).mount('#app')
